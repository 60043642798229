<script lang="tsx">
import { defineComponentCoreUiCounter } from '@core/app/components/core/ui/CoreUiCounter.vue'
import { IconMinus, IconPlus } from '#components'

export default defineComponentCoreUiCounter({
    slots: {
        minusIcon: () => <IconMinus width="12" />,
        plusIcon: () => <IconPlus height="12" />,
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/CoreUiCounter" as *;

@include counter {
    align-items: stretch;

    width: fit-content;
    height: 4rem;

    color: $vut-c-white;
}

@include input {
    width: 4rem;
    max-width: unset;
    height: 100%;
    border: hr-line();

    background: $vut-c-white;
    color: $vut-c-gray;
}

@include button {
    aspect-ratio: 1 / 1;
    height: 100%;
    background: $vut-c-secondary;
    transition: background $vut-trans-time $vut-timing;
}

@include button-hover {
    background: $vut-c-secondary-darker;
}

@include button-disabled {
    opacity: 1;
}
</style>
